@use '@angular/material' as mat;
@import "@angular/material/theming";
@include mat.core();
$angular-primary: mat.define-palette(mat.$blue-palette, 700, 400, 900);
$angular-accent: mat.define-palette(mat.$red-palette, A200, A100, A400);
$angular-warn: mat.define-palette(mat.$yellow-palette, A200, A100, A400);

$lato-typography: mat.define-typography-config(
  $font-family: 'Archivo'
);
@include mat.core($lato-typography);

$blue-grey-palette-easydisplays: (
  50: #eceff1,
  100: #cfd8dc,
  200: #b0bac5,
  300: #90a0ae,
  400: #78859c,
  500: #60758b,
  600: #54617a,
  700: #454f64,
  800: #37404f,
  900: #262d38,
  A100: #cfd5dc,
  A200: #b0bac5,
  A400: #78859c,
  A700: #455364,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$dark-theme-background-palette: (
  status-bar: black,
  app-bar:    map_get($blue-grey-palette-easydisplays, 900),
  background: map_get($blue-grey-palette-easydisplays, 900),
  hover:      rgba(white, 0.04), // TODO(kara): check style with Material Design UX
  card:       map_get($blue-grey-palette-easydisplays, 800),
  dialog:     map_get($blue-grey-palette-easydisplays, 800),
  disabled-button: $white-12-opacity,
  raised-button: map-get($blue-grey-palette-easydisplays, 800),
  focused-button: $white-6-opacity,
  selected-button: map_get($blue-grey-palette-easydisplays, 900),
  selected-disabled-button: map_get($blue-grey-palette-easydisplays, 800),
  disabled-button-toggle: map_get($blue-grey-palette-easydisplays, 1000),
);



$angular-dark-theme: mat.define-dark-theme(
    (
      color: (
        primary: $angular-primary,
        accent: $angular-accent,
        warn: $angular-warn,
      ),
      background: $dark-theme-background-palette,
      foreground: mat.$dark-theme-foreground-palette
    )
);
@include mat.all-component-themes($angular-dark-theme);

@mixin generate($prefix, $property) {
  // List of sizes to generate for each
  $sizes: [0, .25, .5, 1, 1.5, 3];
  // Spacing to multiply the sizes by
  $spacing: 1rem;

  // Loop through all of the sizes(we use @for rather than @each, as we want access to the index)
  @for $i from 1 through length($sizes) {
    // Get the size for the current index
    $size: nth($sizes, $i);

    // Create the rule
    .#{$prefix}-#{$i - 1} {
      #{$property}: $spacing * $size;
    }
  }
}

@include generate(mb, margin-bottom);
