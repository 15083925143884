/* You can add global styles to this file, and also import other style files */
// @import '@angular/material/prebuilt-themes/purple-green.css';
@use '@angular/material' as mat;
@import 'easydisplays-theme.scss';
@import '@angular/material/theming';
@import 'scss/_perfect-scrollbar';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: none !important;
}

@font-face {
  font-family: 'Technology-italic';
  src: url('/assets/fonts/Technology-Italic.ttf');
}

@font-face {
  font-family: 'Technology';
  src: url('/assets/fonts/Technology.ttf');
}

@font-face {
  font-family: 'ScreenMatrix';
  src: url('/assets/fonts/ScreenMatrix.ttf');
}

@font-face {
  font-family: 'Open24DisplaySt';
  src: url('/assets/fonts/Open24DisplaySt.ttf');
}

@font-face {
  font-family: 'Calculator';
  src: url('/assets/fonts/Calculator.ttf');
}

@font-face {
  font-family: 'digital-italic';
  src: url('/assets/fonts/DS-DIGIT.TTF');
}

@font-face {
  font-family: 'digital-7-mono-italic';
  src: url('/assets/fonts/digital-7 (mono italic).ttf');
}

@font-face {
  font-family: 'digital-bold';
  src: url('/assets/fonts/DS-DIGIB.TTF');
}

@font-face {
  font-family: 'AlteHaasGroteskBold';
  src: url('/assets/fonts/new/AlteHaasGroteskBold.ttf');
}

@font-face {
  font-family: 'AlteHaasGroteskRegular';
  src: url('/assets/fonts/new/AlteHaasGroteskRegular.ttf');
}


@font-face {
  font-family: 'commando';
  src: url('/assets/fonts/new/commando.ttf');
}

@font-face {
  font-family: 'NavineDemo-SemiCondensed';
  src: url('/assets/fonts/new/NavineDemo-SemiCondensed.ttf');
}

@font-face {
  font-family: 'NavineDemo-SemiCondensed_Italic';
  src: url('/assets/fonts/new/NavineDemo-SemiCondensed_Italic.ttf');
}

@font-face {
  font-family: 'Patopian_1986';
  src: url('/assets/fonts/new/Patopian_1986.ttf');
}

@font-face {
  font-family: 'Small_LCD_Sign';
  src: url('/assets/fonts/new/Small_LCD_Sign.ttf');
}

.margin-15{
  margin:15px;
}

.mat-card-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  margin-top: -3px !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 5px 5px 10px 0;
}

.app-toolbar {
  position: sticky;
  position: -webkit-sticky; /* For macOS/iOS Safari */
  top: 0; /* Sets the sticky toolbar to be on top */
  z-index: 1000; /* Ensure that your app's content doesn't overlap the toolbar */
}

html, body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  //background-color: #f6f6f6;
}

.fill-space {
  // This fills the remaining space, by using flexbox.
  // Every toolbar row uses a flexbox row layout.
  flex: 1 1 auto;
}

.mat-card-header {
  // border-bottom: 1px solid rgba(0,0,0,1);
  // color: #3f51b5;
  opacity: 0.7;
  margin-bottom: 15px;
}

.mat-card-header .mat-card-title, .mat-card-header .mat-card-header-text {
  align-items: center;
  flex-direction: row;
  display: flex;
  column-gap: 10px;
  margin-bottom: 0 !important;
  white-space: pre-wrap;
}

.mat-card-header .mat-card-title-small {
  font-size: 15px !important;
}

.mat-card-header-text {
  margin: 0 0 !important;
}

.mat-form-field {
  display: block !important;
}

.mat-card-content {
  display: block;
  margin-bottom: 5px;
}

.mat-spinner {
  margin-left: 15px;
}

.app-mat-table {
  width: 100%;
}

th.mat-header-cell {
  // background-color: #f3f3f3;
}

/*.mat-drawer-container {
  display: inline !important;
}*/

.toolbar {
  position: relative;
  z-index: 1000;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 0;
}

.mat-progress-bar {
  margin-bottom: -4px !important;
  z-index: 100 !important;
}

@media screen and (max-width: 960px) {
  th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
    padding-right: 5px !important;
  }

  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
    padding-left: 5px !important;
  }
}

.mat-bread-crumb {
  a {
    text-decoration: none;
    font-weight: 700;
  }

  a:hover {
    text-decoration: underline;
  }

  span {
    color: #eee;
  }
}

.mat-card-actions .mat-button, .mat-card-actions .mat-raised-button, .mat-card-actions .mat-stroked-button {
  margin: 0 5px !important;
}

.mat-card > .mat-card-actions:last-child {
  margin-bottom: -6px !important;
  padding-bottom: 0;
  padding-top: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

::ng-deep {
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: white;
  }

  mat-form-field {
    .mat-hint, input, ::placeholder, .mat-form-field-label {
      color: white;
      font-weight: 700;
    }
  }
}

/* Border */
.mat-form-field-appearance-outline .mat-form-field-outline {
  // color: rgba(255,255,255,0.5);
  color: rgb(30, 136, 229);
  border-width: 2px;
  box-shadow: none !important;
}

/* Font color */
input.mat-input-element {
  color: white;
}

.cdk-global-scrollblock {
  position: static !important;
  overflow-y: hidden !important;;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #424242 inset !important;
  color: white !important;
}

.mat-tab-body-wrapper {
  padding-top: 20px;
}

.mat-bottom-sheet-container {
  min-width: calc(100vw - 60px) !important;
}

.mat-list-base {
  padding-top: 8px;
  padding-bottom: 6px;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(0%) !important;
}

.mat-tab-label {
  .mat-icon {
    margin-right: 10px;
  }
}

.mat-toolbar.mat-primary {
  background: linear-gradient(
      251deg, #1976d2, #1976d2);
  color: white;
}

.snack-error {
  background-color: #E91E63;
  text-shadow: 1px -1px 0 rgba(0, 0, 0, 0.5);
  font-weight: 700;
  color: white;
}

.snack-success {
  background-color: #4CAF50;
  text-shadow: 1px -1px 0 rgba(0, 0, 0, 0.5);
  font-weight: 700;
  color: white;
}

textarea.mat-input-element.cdk-textarea-autosize {
  overflow: hidden;
}

.mat-expansion-panel-header.mat-expanded {
  height: 54px !important;
  margin-bottom: 1px;
  // box-shadow: 0 3px 5px rgb(0 0 0 / 20%);
  // background-color: mat.get-color-from-palette(mat.$blue-palette, 700);
}

.mat-expansion-panel-header-title {
  color: mat.get-color-from-palette(mat.$blue-palette, 600);
}

.mat-expansion-panel-header {
  padding: 0 15px 0 9px !important;
}

.mat-expansion-panel-spacing {
  margin: 5px 0 !important;
}

.mat-expansion-panel-body {
  padding: 0 6px 10px !important;
}

.mat-dialog-actions {
  margin-top: 20px !important;
  margin-bottom: -10px !important;
}

.mat-dialog-title {
  margin-bottom: 20px !important;
  margin-top: -10px !important;
}

::-webkit-scrollbar {
  width: 8px;
  overflow-y: scroll;
  background: grey;
  box-shadow: inset 0 0 4px #707070;
}

::-webkit-scrollbar-thumb {
  background: #4286f4;
  border-radius: 10px;
}

.resizers {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 999999;
  pointer-events: none;
  position: absolute;
}

.item-active > .resizers {
  opacity: 1;
  pointer-events: all;
}

.item-first > .resizers {
  border: 3px solid #72c429 !important;
  .resizer {
    background: #72c429 !important;
    border: 3px solid #72c429 !important;
  }
}

.resizers .resizer.top-left {
  left: -2px;
  top: -2px;
  cursor: move;
}

.resizers .resizer.top-right {
  right: -2px;
  top: -2px;
  cursor: move;
}

.resizers {
  width: 100%;
  height: 100%;
  border: 3px solid #4286f4;
  box-sizing: border-box;
}

.resizers .resizer {
  width: 6px;
  height: 6px;
  border-radius: 0%;
  background: #4286f4;
  border: 3px solid #4286f4;
  position: absolute;
}

.resizers .resizer.bottom-left {
  left: -2px;
  bottom: -2px;
  cursor: move;
}

.resizers .resizer.bottom-right {
  right: -5px;
  bottom: -5px;
  cursor: nwse-resize;
}

.cdk-overlay-backdrop {
  //backdrop-filter: blur(10px);
  opacity: 1;
}

.mat-dialog-container {
  // min-width: 340px !important;
}

.mat-dialog-content {
  // min-width: 340px !important;
  overflow: scroll;
}

.ngx-mat-colorpicker-content {
  display: block;
  border-radius: 2px;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
  background-color: #303030 !important;
  color: rgba(0, 0, 0, .87);
  padding: 16px;
}


.mat-form-field-appearance-outline .mat-form-field-wrapper {
  // margin: 0.1em 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  padding-right: .15em !important;
}

.mat-error {
  text-align: right !important;
}

input[type="color"] {
  -webkit-appearance: none;
  border: none;
  width: 17px;
  height: 17px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
}

.mat-table, .mat-card, .mat-paginator, .mat-bottom-sheet-container, .mat-dialog-container{
  background: rgba(20, 20, 20, 0.9);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(0,0,0,1);
  border-radius: 3px;
  opacity: 0.97;
  padding: 10px;
}

.mat-paginator-page-size {
  margin-top: 10px;
}

.hr{
  opacity: 0.2;
}
